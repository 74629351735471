import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import Page from "../components/project_d.js"
import IndexSPA from "../components/IndexSPA"

// export default Page

const Index = (props) => {
   return (
      <>
        <Helmet
        title="一個論述的提案(下) / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫"
        meta={[
          {
            name: `description`,
            content: "一個論述的提案(下)",
          },
          {
            property: `og:title`,
            content: "一個論述的提案(下) / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫",
          },
          {
            property: `og:description`,
            content: "一個論述的提案(下)",
          },
          // {
          //   property: `og:url`,
          //   content: 'https://online.home-to-all.com' + window.location.pathname,
          // },
          {
            property: `og:image`,
            content: 'https://online.home-to-all.com' + require('../images/blog/post_1_10.jpg')
          }
        ]}
        >
         </Helmet>
         <IndexSPA />
      </>
   )
 };
 
 // Index.propTypes = {}
 
 export default Index;